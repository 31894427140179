/* React */
import React, { Component } from 'react';

/* Components */
import GoogleMapReact from 'google-map-react';
import Marker from './Marker/Marker';

/* Styles */
import './Map.scss';

/* Map */
class Map extends Component {
    /**
     * State:
     * 
     * clients - clients array
     * openedMarker - number of opened marker
     */
    constructor(props) { 
        super(props); 
        this.state = {
            clients: this.props.clients,
            openedMarker: 0,
            opened: false,
            savedIndex: -1,
            center: this.props.center,
            zoom: this.props.zoom
        }; 
    };

    setOpenedMarker = (marker) => {
        this.setState({
            openedMarker: marker
        })
    }

    openDescription = (index) => {
        if (this.state.opened === false) {
            console.log(document.getElementsByClassName('marker_description'));
            document.getElementsByClassName('marker_description')[index].classList.remove('hidden');
            this.setState({
                opened: true,
                savedIndex: index
            });
        } else if (this.state.opened === true) {
            if (index === this.state.savedIndex) {
                document.getElementsByClassName('marker_description')[this.state.savedIndex].classList.add('hidden');
                this.setState({
                    opened: false
                });
            } else {
                document.getElementsByClassName('marker_description')[this.state.savedIndex].classList.add('hidden');
                document.getElementsByClassName('marker_description')[index].classList.remove('hidden');
                this.setState({
                    savedIndex: index
                });
            };
        };
    };

    render() {

        const Markers = this.props.mapClients
            .map((client, index) => {  
                return (
                    <Marker
                        openDescription={this.openDescription}
                        key={index + 'c'}
                        index={index}
                        lat={this.props.mapClients[index].lat}
                        lng={this.props.mapClients[index].lon}
                        description={this.props.mapClients[index].ip_addr}
                        descriptionCims={this.props.mapClients[index].cims_version}
                        setOpenedMarker={this.setOpenedMarker}
                        openedMarker={this.state.openedMarker}
                    />
                );
            });

        return (
            <div id="map" className="map_root" style={{height: this.props.mapHeight + '%', width: this.props.mapWidth + '%', transition: '.5s ease-in-out'}}>
                <GoogleMapReact zoom={this.props.zoom} center={this.props.center} bootstrapURLKeys={{ key: 'AIzaSyC2wZTbrU3Tn2udekcewHP4EzVO2Hr-Bx8'}}>
                    {Markers}
                </GoogleMapReact>
            </div>
        );
    };

};

export default Map;