/* React */
import React, {Component} from 'react';

/* Styles */
import './Marker.scss'

/* Marker */
class Marker extends Component {
    constructor(props) { 
        super(props); 
        this.state = {
            id: Math.random(),
            changedId: 0
        }; 
    };

    render() {

        return (
            <div index={this.props.index}>
                <img onClick={() => {this.props.openDescription(this.props.index)}} className="marker" src="./marker.png" width="20px" alt="marker"></img>
                <div className="marker_description hidden" id={this.state.id}>
                    <div onClick={() => {this.props.openDescription(this.props.index)}} className="marker_description_close">x</div>
                    <div className="marker_description_text">Client IP - {this.props.description}</div>
                    <div className="marker_description_text">Client cims version - {this.props.descriptionCims}</div>
{/*                     <div className="marker_description_change" onClick={() => {this.props.resizeMap(this.props.index)}}>Изменить</div> */}
                </div>
            </div>
        );

    };
};

export default Marker;