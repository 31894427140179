/* React */
import React, {Component} from 'react';

/* Components */
import Clients from '../Clients/Clients';
import Pagination from '../Pagination/Pagination';

/* Styles */
import './Regions.scss';

/* Regions */
class Regions extends Component {
    /**
     * State:
     * 
     * maxPages - count of pages
     * offset - offset
     * activePage - number of active page
     * buttons - object that contains buttons data 
     */
    constructor(props) { 
        super(props); 
        this.state = {
            maxPages: 1,
            offset: 0,
            activePage: 1,
            buttons: {
                one: 0,
                two: 1,
                three: 2
            },
            unique: Math.random()
        }; 
    };

    /**
     * setActivePages()
     * 
     * Set correct state for buttons data
     */
    setActivePages = () => {
        if (this.state.activePage === 1) {
            this.setState({
                buttons: {
                    one: 0,
                    two: 1,
                    three: 2
                }
            });
        } else {
            this.setState({
                buttons: {
                    one: this.state.activePage - 1,
                    two: this.state.activePage,
                    three: this.state.activePage + 1
                }
            });
        };
    };

    /**
     * pageForward()
     * 
     * Page forward on pagination
     */
    pageForward = () => {
        this.setState({
            activePage: this.state.activePage + 1,
            offset: this.state.offset + 1
        });
        setTimeout(() => {
            this.getClients();
            this.setActivePages();
        }, 100);
    };

    /**
     * pageBackward()
     * 
     * Page backward on pagination
     */
    pageBackward = () => {
        this.setState({
            activePage: this.state.activePage + -1,
            offset: this.state.offset - 1
        });
        setTimeout(() => {
            this.getClients();
            this.setActivePages();
        }, 100);
    };

    /**
     * movePage()
     * 
     * direction: 'forward' or 'backward'
     * 
     * Move pagination by direction
     */
    movePage = (direction) => {
        if (direction === 'forward') {
            this.pageForward();
        } else if (direction === 'backward') {
            this.pageBackward();
        };
    };

    /**
     * Render()
     */
    render() {

        /* Regions */
        const Regions = () => this.props.regions.map((region, i) => {
            return (
                <div key={Math.random()}>
                    <div className="client_list_region_region" onClick={() => {this.props.openRegion(this.props.regions[i].code, this.props.regions[i].name)}}>
                        <span>{this.props.regions[i].name} - {this.props.regions[i].code}.</span>
                        <span className="material-icons arrow">arrow_right_alt</span>
                        <div className="region_clients">
                            <Clients
                                clients={this.props.regionClients}
                                focusOnClient={this.props.focusOnClient}
                                focusMap={this.props.focusMap}
                            />
                        </div>
                    </div>
                    <div>
                    {this.state.maxPages > 1 &&
                        <Pagination
                            maxPages={this.state.maxPages}
                            activePage={this.state.activePage}
                            pageForward={this.pageForward}
                            pageBackward={this.pageBackward}
                            movePage={this.movePage}
                            buttons={this.state.buttons}
                        />
                    }
                    </div>
                </div>
            );
        });
        
        /**
         * Return()
         */
        return (
            <div>
                <Regions/>
            </div>
        );

    };
};

export default Regions;