/* React */
import React from 'react';
import ReactDOM from 'react-dom';

/* Components */
import App from './App/App';
import * as serviceWorker from './serviceWorker';

/* Styles */
import './index.scss';

/**
 * Render()
 */
ReactDOM.render(

    <App />
  
,document.getElementById('root'));

serviceWorker.unregister();