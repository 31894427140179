/* React */
import React, { Component } from 'react';
import axios from 'axios';

/* Components */
import List from '../List/List';
import Map from '../Map/Map';

/* Styles */
import './App.scss';


/* App */
class App extends Component {
    /**
     * State:
     * 
     * clients - array of clients.
     * mapWidth - width of .map container
     * mapHeight - height of .map container
     * zoom - map zoom (1 - world)
     * center - map center { lat: int, lng: int }
     * opened - map information state
     * chosedClient - chosed client object for map
     */
    constructor(props) { 
        super(props); 
        this.state = {
            /* Main */
            clients: [],
            mapClients: [],
            chosedClient: {},
            moreClientInfo: {},

            /* Map settings */
            mapWidth: 100,
            mapHeight: 100,
            zoom: 1,
            center: {
                lat: 60,
                lng: 100
            },
            opened: false,

            ip_addr: '',
            cims_version: '',
            reg_date: '',
            region: '',
            name: '',
            city: '',
            zipcode: '',
            region_code: '',
            post_address: '',
            contact_manager: '',
            contact_phone: '',
            contact_email: '',
            req_stat: '',
            req_date: '',
            news_agreement: '',
            lon: '',
            lat: '',
            fingerprint: '',

            savedOffset: null
        }; 
    };

    /**
     * setClients()
     * 
     * clients - array of clients
     *  
     * Set clients state in App.jsx from ClientList.jsx
     */
    setClients = (clients) => {
        this.setState({
            clients: clients
        });
    };

    /**
     * setMapClients()
     * 
     * clients - array of clients
     * 
     * Set clients state
     */
    setMapClients = (clients) => {
        this.setState({
            mapClients: clients
        });
    };

    /**
     * getFullClientInfo
     */
    getFullClientInfo = () => {
        axios.get('https://team.carddex.ru/api/cimsgm/clients.json/' + this.state.chosedClient.uuid)
        .then((response) =>  {
            console.log(response)
            if (response.data.result.code === 0) {
                this.setState({
                    moreClientInfo: response.data.client,
                    chosedClient: response.data.client
                })
            } else {
                console.error('Get exception: ');
                console.error(response.data.result.code);
                console.error(response.data.result.message);
            };
        })
        .catch((error) => {
            console.error(error);
        });
    }

    /**
     * focusOnClient()
     * 
     * client - object
     * 
     * Set map center on client
     */
    focusOnClient = (client, type) => {
        if (type === 2) {
            if (document.getElementById('map').style.width === "100%") {
                this.setState({
                    center: {
                        lat: client.lat,
                        lng: client.lon
                    }
                });
            };
        } else {
            this.setState({
                center: {
                    lat: this.state.chosedClient.lat,
                    lng: this.state.chosedClient.lon
                }
            });
        };
    };

    /**
     * focusMap()
     * 
     * client - object
     * direction - int: 0 - close, 1 - open.
     * 
     * Resize map from w/h 100% to w/h 20/50%
     * Set center of map on this client
     * Change zoom of map to 12
     * Set opened state to true
     * 
     * or
     * 
     * Resizer map from w/h 20/50% to w/h 100%
     * Set center of map on Russia
     * Change zoom of map to 1 (whole world)
     * Set opened state to false
     */
    focusMap = (client, direction, code) => {
        if (code === 's') {
            this.removeOffset()
            let a = document.getElementsByClassName('cl')
            for(let i = 0; i < a.length; i++) {
                a[i].classList.remove('active');
            }
        }
        this.setState({
            chosedClient: client
        });
        setTimeout(() => {
            if (direction === 0 && this.state.opened === true) {
                this.setState({
                    mapHeight: 100,
                    mapWidth: 100,
                    zoom: 1,
                    center: {
                        lat: 60,
                        lng: 100
                    },
                    opened: false
                });
            }
            else if (direction === 1 && this.state.opened === false) {
                this.setState({
                    opened: true,
                    mapHeight: 70,
                    mapWidth: 20,
                    zoom: 12,
                });
            };
            this.focusOnClient();
        }, 100);
    };

    /**
     * setMoreClientInfo()
     * 
     * client - client object
     * 
     * Set more client info in state
     */
    setMoreClientInfo = (client) => {
        this.setState({
            moreClientInfo: client,

            ip_addr: client.ip_addr,
            cims_version: client.cims_version,
            reg_date: client.reg_date,
            region: client.region,
            name: client.name,
            city: client.city,
            zipcode: client.zipcode,
            region_code: client.region_code,
            post_address: client.post_address,
            contact_manager: client.contact_manager,
            contact_phone: client.contact_phone,
            contact_email: client.contact_email,
            req_stat: client.month_req_stat,
            req_date: client.last_req_date,
            news_agreement: client.news_agreement,
            lon: client.lon,
            lat: client.lat,
            fingerprint: client.fingerprint

        });
    };

    /**
     * applyChanges()
     * 
     * Send changes in state to backend
     */
    applyChanges = () => {
        console.log({
            p_client_uuid: this.state.chosedClient.uuid,
            p_manager_fio: this.state.contact_manager,
            p_client_name: this.state.name,
            p_phone: this.state.contact_phone,
            p_email: this.state.contact_email,
            p_client_address: this.state.post_address
        });
        axios.post('https://team.carddex.ru/api/cimsgm/clients/', {
            p_client_uuid: this.state.chosedClient.uuid,
            p_manager_fio: this.state.contact_manager,
            p_client_name: this.state.name,
            p_phone: this.state.contact_phone,
            p_email: this.state.contact_email,
            p_client_address: this.state.post_address
        })
        .then((response) =>  {
            console.log(response);
            this.getFullClientInfo();
        })
        .catch((error) => {
            console.error(error);
        });
    }

    /**
     * inputChange()
     * 
     * Handle input change for state
     */
    inputChange = (e, type) => {
        this.setState({
            [type]: e.target.value
        })
    }

    /**
     * removeChanges()
     * 
     * Set changes state to default
     */
    removeChanges = () => {
        this.setState({
            ip_addr: this.state.moreClientInfo.ip_addr,
            cims_version: this.state.moreClientInfo.cims_version,
            reg_date: this.state.moreClientInfo.reg_date,
            region: this.state.moreClientInfo.region,
            name: this.state.moreClientInfo.name,
            city: this.state.moreClientInfo.city,
            zipcode: this.state.moreClientInfo.zipcode,
            region_code: this.state.moreClientInfo.region_code,
            post_address: this.state.moreClientInfo.post_address,
            contact_manager: this.state.moreClientInfo.contact_manager,
            contact_phone: this.state.moreClientInfo.contact_phone,
            contact_email: this.state.moreClientInfo.contact_email,
            req_stat: this.state.moreClientInfo.month_req_stat,
            req_date: this.state.moreClientInfo.last_req_date,
            news_agreement: this.state.moreClientInfo.news_agreement,
            lon: this.state.moreClientInfo.lon,
            lat: this.state.moreClientInfo.lat,
            fingerprint: this.state.moreClientInfo.fingerprint
        });
    }

    removeOffset = () => {
        this.setState({
            savedOffset: null
        })
    }

    setOffset = (off) => {
        this.setState({
            savedOffset: off 
        })
    }

    /**
     * Render()
     */
    render() {

        /**
         * Return()
         */
        return (
            <div className="container">
                <List setOffset={this.setOffset} removeOffset={this.removeOffset} savedOffset={this.state.savedOffset} setMoreClientInfo={this.setMoreClientInfo} setMapClients={this.setMapClients} setClients={this.setClients} focusOnClient={this.focusOnClient} focusMap={this.focusMap}/>
                <div className="container_map">
                    <Map mapClients={this.state.mapClients} setMoreClientInfo={this.setMoreClientInfo} mapWidth={this.state.mapWidth} mapHeight={this.state.mapHeight} center={this.state.center} zoom={this.state.zoom} clients={this.state.clients}/>
                    { this.state.opened === true &&
                        <div className="container_map_content">
                            <div className="container_map_content_close" onClick={() => {this.focusMap([], 0, 's')}}>
                                Закрыть
                            </div>
                            <div className="container_map_content_data">
                                <div style={{display: 'flex'}}><div>Client uuid:</div><input disabled onChange={(e) => {this.inputChange(e, 'uuid')}} className="info_input" value={this.state.chosedClient.uuid || ''}></input></div>
                                <div style={{display: 'flex'}}><div>Client ip:</div><input disabled onChange={(e) => {this.inputChange(e, 'ip_addr')}} className="info_input" value={this.state.ip_addr || ''}></input></div>
                                <div style={{display: 'flex'}}><div>Client cims version:</div><input disabled onChange={(e) => {this.inputChange(e, 'cims_version')}} className="info_input" value={this.state.cims_version || ''}></input></div>
                                <div style={{display: 'flex'}}><div>Client registration date:</div><input disabled onChange={(e) => {this.inputChange(e, 'reg_date')}} className="info_input" value={this.state.reg_date || ''}></input></div>
                                <div style={{display: 'flex'}}><div>Client region:</div><input disabled onChange={(e) => {this.inputChange(e, 'region')}} className="info_input" value={this.state.region || ''}></input></div>
                                <div style={{display: 'flex'}}><div>Client name:</div><input onChange={(e) => {this.inputChange(e, 'name')}} className="info_input" value={this.state.name || ''}></input></div>
                                <div style={{display: 'flex'}}><div>Client city:</div><input disabled onChange={(e) => {this.inputChange(e, 'city')}} className="info_input" value={this.state.city || ''}></input></div>
                                <div style={{display: 'flex'}}><div>Client zip:</div><input disabled onChange={(e) => {this.inputChange(e, 'zipcode')}} className="info_input" value={this.state.zipcode || ''}></input></div>
                                <div style={{display: 'flex'}}><div>Client region code:</div><input disabled onChange={(e) => {this.inputChange(e, 'region_code')}} className="info_input" value={this.state.region_code || ''}></input></div>
                                <div style={{display: 'flex'}}><div>Client post address:</div><input onChange={(e) => {this.inputChange(e, 'post_address')}} className="info_input" value={this.state.post_address || ''}></input></div>
                                <div style={{display: 'flex'}}><div>Client contact manager:</div><input onChange={(e) => {this.inputChange(e, 'contact_manager')}} className="info_input" value={this.state.contact_manager || ''}></input></div>
                                <div style={{display: 'flex'}}><div>Client contact phone:</div><input onChange={(e) => {this.inputChange(e, 'contact_phone')}} className="info_input" value={this.state.contact_phone || ''}></input></div>
                                <div style={{display: 'flex'}}><div>Client contact email:</div><input onChange={(e) => {this.inputChange(e, 'contact_email')}} className="info_input" value={this.state.contact_email || ''}></input></div>
                                <div style={{display: 'flex'}}><div>Client month requests:</div><input disabled onChange={(e) => {this.inputChange(e, 'req_stat')}} className="info_input" value={this.state.req_stat || ''}></input></div>
                                <div style={{display: 'flex'}}><div>Client last request date:</div><input disabled onChange={(e) => {this.inputChange(e, 'req_date')}} className="info_input" value={this.state.req_date || ''}></input></div>
                                <div style={{display: 'flex'}}><div>Client news agreement:</div><input disabled onChange={(e) => {this.inputChange(e, 'news_agreement')}} className="info_input" value={this.state.news_agreement || ''}></input></div>
                                <div style={{display: 'flex'}}><div>Client longitude:</div><input disabled onChange={(e) => {this.inputChange(e, 'lon')}} className="info_input" value={this.state.lon || ''}></input></div>
                                <div style={{display: 'flex'}}><div>Client latitude:</div><input disabled onChange={(e) => {this.inputChange(e, 'lat')}} className="info_input" value={this.state.lat || ''}></input></div>
                                <div style={{display: 'flex'}}><div>Client fingerprint:</div><input disabled onChange={(e) => {this.inputChange(e, 'fingerprint')}} className="info_input" value={this.state.fingerprint || ''}></input></div>
                                <div className="comment">
                                    <div className="comment_buttons">
                                        <div className="comment_apply" onClick={this.applyChanges}>Изменить</div>
                                        <div className="comment_back" onClick={this.removeChanges}>Отменить</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );

    };
};

export default App;