/* React */
import React from 'react';

/* Styles */
import './Clients.scss';

/* Client */
const Clients = ({ clients, focusOnClient, getMoreClientInfo }) => clients.map((client, i) => {

    /**
     * Return()
     */
    return (
        <div key={i} id={i+'c'} className="container_list_client cl" onClick={() => {focusOnClient(client, 2)}}>
            <span>{client.name}</span>
            <div className="container_list_client_description" onClick={() => {getMoreClientInfo(client, i + 'c')}}>
                Подробнее
                <div className="container_list_client_description_way"/>
            </div>
        </div>
    );
    
});

export default Clients;