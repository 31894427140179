/* React */
import React, { Component } from 'react';

/* Libs */
import axios from 'axios';

/* Components */
import Clients from './Clients/Clients';
import Regions from './Regions/Regions';
import Pagination from './Pagination/Pagination';

/* Styles */
import './List.scss';

/* List */
class List extends Component {
    /**
     * State:
     * 
     * clients - clients array
     * regions - regions array
     * regionClients - region clients array
     * activePanel - active switcher panel ('all' or 'region')
     * 
     * {any}MaxPages - count of pages
     * {any}Offset - offset
     * {any}ActivePage - number of active page
     * {any}Buttons - object that contains buttons data 
     */
    constructor(props) { 
        super(props); 
        this.state = {
            /* Main */
            clients: [],
            regions: [],
            regionClients: [],
            regionOpened: 0,
            chosenRegionName: '',

            /* Switcher */
            activePanel: 'all',

            /* All clients pagination */
            allClientsMaxPages: 0,
            allClientsOffset: 0,
            allClientsActivePage: 1,
            allClientsButtons: {
                one: 0,
                two: 1,
                three: 2 
            },

            /* Region pagination */
            regionsMaxPages: 0,
            regionsOffset: 0,
            regionsActivePage: 1,
            regionsButtons: {
                one: 0,
                two: 1,
                three: 2
            },

            /* Region clients pagination */
            regionClientsOffset: 0,
            regionClientsMaxPages: 0,
            regionClientsActivePage: 1,
            regionClientsButtons: {
                one: 0,
                two: 1,
                three: 2
            },

            isActive: false,
            lastActive: ''
        }; 
    };

    /**
     * selectOption()
     * 
     * type: 'all' or 'region'
     * 
     * Select option on switcher
     */
    selectOption = (type) => {
        let clientsElement = document.getElementById('clientsElement');
        let regionsElement = document.getElementById('regionsElement');
        if (type === 'all') {
            if (clientsElement.classList.length < 2) {
                clientsElement.classList.add('selected');
                regionsElement.classList.remove('selected');
            };
            this.getClients();
        } else {
            if (regionsElement.classList.length < 2) {
                regionsElement.classList.add('selected');
                clientsElement.classList.remove('selected');
            };
            this.getRegions();
        };
        this.setState({
            activePanel: type
        });
    };
    
    /**
     * getClients()
     * 
     * GET clients request
     */
    getClients = () => {
        axios.get('https://team.carddex.ru/api/cimsgm/clients.json?offset=' + this.state.allClientsOffset + '&limit=16')
        .then((response) =>  {
            if (response.data.result.code === 0) {
                this.setState({
                    clients: response.data.clients,
                    allClientsMaxPages: Math.ceil(response.data.total_count / 16)
                });
                this.props.setClients(response.data.clients);
            } else {
                console.error('Get exception: ');
                console.error(response.data.result.code);
                console.error(response.data.result.message);
            };
        })
        .catch((error) => {
            console.error(error);
        });
    };

    getAllClients = () => {
        axios.get('https://team.carddex.ru/api/cimsgm/clients.json?limit=-1')
        .then((response) =>  {
            if (response.data.result.code === 0) {
                console.log(response);
                this.props.setMapClients(response.data.clients);
            } else {
                console.error('Get exception in all clients: ');
                console.error(response.data.result.code);
                console.error(response.data.result.message);
            };
        })
        .catch((error) => {
            console.error(error);
        });
    };

    /**
     * getRegions()
     * 
     * GET regions request
     */
    getRegions = () => {
        axios.get('https://team.carddex.ru/api/cimsgm/regions.json?offset=' + this.state.regionsOffset + '&limit=16')
        .then((response) =>  {
            if (response.data.result.code === 0) {
                this.setState({
                    regions: response.data.regions,
                    regionsMaxPages: Math.ceil(response.data.total_count / 16)
                });
            } else {
                console.error('Get exception: ');
                console.error(response.data.result.code);
                console.error(response.data.result.message);
            };
        })
        .catch((error) => {
            console.error(error);
        });
    };

    /**
     * openRegion()
     * 
     * regionCode - region code
     * 
     * GET region client request
     */
    openRegion = (regionCode) => {
        this.setState({
            regionOpened: 1
        })
        axios.get('https://team.carddex.ru/api/cimsgm/regions.json/' + regionCode + '?offset=' + this.state.regionClientsOffset + '&limit=14')
        .then((response) =>  {
            if (response.data.result.code === 0) {
                this.setState({
                    regionClients: response.data.clients,
                    regionClientsMaxPages: Math.ceil(response.data.regions.clients_count / 14),
                    regionOpened: 1,
                    chosenRegionName: response.data.regions.name
                });
                this.props.setClients(response.data.clients);
            } else {
                console.error('Get exception: ');
                console.error(response.data.result.code);
                console.error(response.data.result.message);
            };
        })
        .catch((error) => {
            console.error(error);
        });
    };

    /**
     * getMoreClientInfo()
     * 
     * GET request for more client information
     */
    getMoreClientInfo = (client, type) => {
        if (this.props.savedOffset !== null) {
            console.log('remove offset')
            this.props.removeOffset()
        }
        if (this.state.isActive === false) {
            document.getElementById(type).classList.add('active');
            this.setState({
                isActive: true,
                lastActive: type
            })
        } else {
            if (this.state.lastActive !== type) {
                console.log('remove saved offset')
                document.getElementById(this.state.lastActive).classList.remove('active');
                document.getElementById(type).classList.add('active');
                this.setState({
                    lastActive: type
                })
            }
        }
        if (type !== 2) {
            this.props.focusMap(client, 1);
        };
        axios.get('https://team.carddex.ru/api/cimsgm/clients.json/' + client.uuid)
        .then((response) =>  {
            if (response.data.result.code === 0) {
                this.props.setMoreClientInfo(response.data.client);
            } else {
                console.error('Get exception: ');
                console.error(response.data.result.code);
                console.error(response.data.result.message);
            };
        })
        .catch((error) => {
            console.error(error);
        });
    }

    /**
     * setActivePages()
     * 
     * type: 'allClients', 'region', 'regionClients'
     * 
     * Set correct state for buttons data
     */
    setActivePages = (type) => {
        if (type === 'allClients') {
            if (this.state.allClientsActivePage === 1) {
                this.setState({
                    allClientsButtons: {
                        one: 0,
                        two: 1,
                        three: 2
                    }
                });
            } else {
                this.setState({
                    allClientsButtons: {
                        one: this.state.allClientsActivePage - 1,
                        two: this.state.allClientsActivePage,
                        three: this.state.allClientsActivePage + 1
                    }
                });
            };
        } else if (type === 'region') {
            if (this.state.regionsActivePage === 1) {
                this.setState({
                    regionsButtons: {
                        one: 0,
                        two: 1,
                        three: 2
                    }
                });
            } else {
                this.setState({
                    regionsButtons: {
                        one: this.state.regionsActivePage - 1,
                        two: this.state.regionsActivePage,
                        three: this.state.regionsActivePage + 1
                    }
                });
            };
        } else if (type === 'regionClients') {
            if (this.state.regionClientsActivePage === 1) {
                this.setState({
                    regionClientsButtons: {
                        one: 0,
                        two: 1,
                        three: 2
                    }
                });
            } else {
                this.setState({
                    regionClientsButtons: {
                        one: this.state.regionClientsActivePage - 1,
                        two: this.state.regionClientsActivePage,
                        three: this.state.regionClientsActivePage + 1
                    }
                });
            };
        };
    };

    /**
     * pageForward()
     * 
     * type: 'allClients', 'regions', 'regionClients' 
     * 
     * Page forward on pagination
     */
    pageForward = (type) => {
        if (this.props.savedOffset !== null) {
            if (this.state.allClientsOffset + 1 === this.props.savedOffset) {
                console.log('activate')
                document.getElementById(this.state.lastActive).classList.add('active')
                this.setState({
                    isActive: true
                })
                this.props.removeOffset()
            }
        } else {
            if (this.state.isActive === true) {
                console.log('save this offset')
                this.setState({
                    isActive: false
                })
                this.props.setOffset(this.state.allClientsOffset);
                document.getElementById(this.state.lastActive).classList.remove('active')
            }
        }
        if (type === 'allClients') {
            this.setState({
                allClientsActivePage: this.state.allClientsActivePage + 1,
                allClientsOffset: this.state.allClientsOffset + 1
            });
            setTimeout(() => {
                this.getClients();
                this.setActivePages('allClients');
            }, 100);
        } else if (type === 'regions') {
            this.setState({
                regionsActivePage: this.state.regionsActivePage + 1,
                regionsOffset: this.state.regionsOffset + 1
            });
            setTimeout(() => {
                this.getRegions();
                this.setActivePages('regions');
            }, 100);
        } else if (type === 'regionClients') {
            this.setState({
                regionClientsActivePage: this.state.regionClientsActivePage + 1,
                regionClientsOffset: this.state.regionClientsOffset + 1
            });
            setTimeout(() => {
                this.openRegion();
                this.setActivePages('regionClients');
            }, 100);
        };
    };

    /**
     * pageBackward()
     * 
     * type: 'allClients', 'regions', 'regionClients' 
     * 
     * Page backward on pagination
     */
    pageBackward = (type) => {
        if (this.props.savedOffset !== null) {
            console.log('already have saved offset')
            if (this.state.allClientsOffset - 1 === this.props.savedOffset) {
                console.log('activate')
                document.getElementById(this.state.lastActive).classList.add('active')
                this.setState({
                    isActive: true
                })
                this.props.removeOffset()
            }
        } else {
            if (this.state.isActive === true) {
                console.log('save this offset')
                this.setState({
                    isActive: false
                })
                this.props.setOffset(this.state.allClientsOffset)
                document.getElementById(this.state.lastActive).classList.remove('active')
            }
        }
        if (type === 'allClients') {
            this.setState({
                allClientsActivePage: this.state.allClientsActivePage + -1,
                allClientsOffset: this.state.allClientsOffset - 1
            });
            setTimeout(() => {
                this.getClients();
                this.setActivePages('allClients');
            }, 100);
        } else if (type === 'regions') {
            this.setState({
                regionsActivePage: this.state.regionsActivePage + -1,
                regionsOffset: this.state.regionsOffset - 1
            });
            setTimeout(() => {
                this.getRegions();
                this.setActivePages('regions');
            }, 100);
        } else if (type === 'regionClients') {
            this.setState({
                regionClientsActivePage: this.state.regionClientsActivePage + -1,
                regionClientsOffset: this.state.regionClientsOffset - 1
            });
            setTimeout(() => {
                this.openRegion();
                this.setActivePages('regionClients');
            }, 100);
        };
    };

    /**
     * movePage()
     * 
     * direction: 'forward' or 'backward'
     * type: 'allClients', 'regions, 'regionClients'
     * 
     * Move pagination by direction
     */
    movePage = (direction, type) => {
        if (direction === 'forward') {
            if (type === 'allClients') {
                this.pageForward('allClients');
            } else if (type === 'regions') {
                this.pageForward('regions');
            } else if (type === 'regionClients') {
                this.pageForward('regionClients');
            };
        } else if (direction === 'backward') {
            if (type === 'allClients') {
                this.pageBackward('allClients');
            } else if (type === 'regions') {
                this.pageBackward('regions');
            } else if (type === 'regionClients') {
                this.pageBackward('regionClients');
            };
        };
    };

    /**
     * closeRegion()
     * 
     * Close region and go back to regions list
     */
    closeRegion = () => {
        this.setState({
            regionOpened: 0,
            regionClients: [],
            regionClientsActivePage: 1,
            regionClientsOffset: 0,
            regionClientsMaxPages: 0,
            chosenRegionName: null
        });
        setTimeout(() => {
            this.setActivePages('regionClients');
            this.getRegions();
        }, 100);
    };
    
    /**
     * componentDidMount()
     * 
     * On component mount
     */
    componentDidMount = () => {
        this.getRegions();
        this.getClients();
        this.getAllClients();
    };

    /**
     * Render()
     */
    render() {

        /**
         * Return()
         */
        return (
            <div className="container_list">
                <div className="container_list_switcher">
                    <div id="clientsElement" onClick={() => {this.selectOption('all')}} className="container_list_switcher_option selected">
                        <span>Все клиенты</span>
                    </div>
                    {/*
                    <div id="regionsElement" onClick={() => {this.selectOption('region')}} className="container_list_switcher_option">
                        <span>По регионам</span>
                    </div>
                    */}
                </div>
                {this.state.activePanel === 'all' &&
                    <div className="container_list_clients">
                        <Clients
                            clients={this.state.clients}
                            focusOnClient={this.props.focusOnClient}
                            getMoreClientInfo={this.getMoreClientInfo}
                        />
                        { this.state.allClientsMaxPages > 1 &&
                            <Pagination 
                                maxPages={this.state.allClientsMaxPages}
                                activePage={this.state.allClientsActivePage}
                                pageForward={this.pageForward}
                                pageBackward={this.pageBackward}
                                movePage={this.movePage}
                                type='allClients'
                                buttons={this.state.allClientsButtons}
                            />
                        }
                    </div>
                }
                {this.state.activePanel === 'region' &&
                    <div className="client_list_regions">
                        { this.state.regionOpened === 0 &&
                            <Regions
                                regions={this.state.regions}
                                regionClients={this.state.regionClients}
                                focusMap={this.props.focusMap}
                                focusOnClient={this.props.focusOnClient}
                                openRegion={this.openRegion}
                            />
                        }
                        { this.state.regionsMaxPages > 1 && this.state.regionOpened === 0 &&
                            <Pagination
                                maxPages={this.state.regionsMaxPages}
                                activePage={this.state.regionsActivePage}
                                pageForward={this.pageForward}
                                pageBackward={this.pageBackward}
                                movePage={this.movePage}
                                type='regions'
                                buttons={this.state.regionsButtons}
                            />
                        }
                        { this.state.regionOpened === 1 &&
                            <div className="client_list_region_clients">
                                <div className="client_list_region_clients_back" onClick={this.closeRegion}>Назад к регионам.</div>
                                <span className="client_list_region_clients_data">Клиенты в регионе {this.state.chosenRegionName}:</span>
                                <Clients
                                    clients={this.state.regionClients}
                                    focusOnClient={this.props.focusOnClient}
                                    getMoreClientInfo={this.getMoreClientInfo}
                                />
                            </div>
                        }
                        { this.state.regionOpened === 1 && this.state.regionClientsMaxPages > 1 &&
                            <Pagination
                                maxPages={this.state.regionClientsMaxPages}
                                activePage={this.state.regionClientsActivePage}
                                pageForward={this.pageForward}
                                pageBackward={this.pageBackward}
                                movePage={this.movePage}
                                type='regionsClient'
                                buttons={this.state.regionClientsButtons}
                            />
                        }
                    </div>
                }
            </div>
        );

    };
};

export default List;