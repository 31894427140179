/* React */
import React, { Component } from 'react';

/* Styles */
import './Pagination.scss';

/* Pagination */
class Pagination extends Component {

    /**
     * Render()
     */
    render() {

        /**
         * Return()
         */
        return (
            <div className="container_list_pagination">
                <div className="container_list_pagination_pagesInfo">
                    Всего страниц: {this.props.maxPages}.
                </div>
                <div className="container_list_pagination_main">
                    <div className="container_list_pagination_back">
                        { this.props.activePage !== 1 &&
                            <span onClick={() => {this.props.pageBackward(this.props.type)}} className="material-icons">chevron_left</span>
                        }
                    </div>
                    { this.props.buttons.one !== 0 &&
                        <div className="container_list_pagination_page" onClick={() => {this.props.movePage('backward', this.props.type)}}>{this.props.buttons.one}</div>
                    }
                    <div className="container_list_pagination_page active">{this.props.buttons.two}</div>
                    { this.props.buttons.three !== this.props.maxPages + 1 &&
                        <div className="container_list_pagination_page" onClick={() => {this.props.movePage('forward', this.props.type)}}>{this.props.buttons.three}</div>
                    }
                    <div className="container_list_pagination_forward" >
                        { this.props.activePage !== this.props.maxPages &&
                            <span onClick={() => {this.props.pageForward(this.props.type)}} className="material-icons">chevron_right</span>
                        }
                    </div>
                </div>
            </div>
        );

    };
};

export default Pagination;